import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionTracker, initialActionTracker } from "../utils";
import { User } from "@/utils/types/common";
import { loginThunk } from "../thunks/authThunk";

type AuthState = {
  login: ActionTracker;

  user: User | null;
  token: string | null;
};

const initialState: AuthState = {
  login: initialActionTracker,

  user: null,
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(loginThunk.pending, (state) => {
      state.login = {
        ...initialActionTracker,
        isLoading: true,
      };
    });
    builder.addCase(loginThunk.fulfilled, (state, { payload }) => {
      state.login = {
        ...initialActionTracker,
        isSuccess: true,
        successMessage: "Logged in successfully",
      };

      state.token = payload?.token;
      state.user = payload?.user;
    });
    builder.addCase(loginThunk.rejected, (state, { error }) => {
      state.login = {
        ...initialActionTracker,
        errorMessage: error.message || "",
        isError: true,
      };
    });
  },
});

export default authSlice.reducer;
export const { setUser } = authSlice.actions;
